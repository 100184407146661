.file-upload {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
}

.file-upload__input {
    display: none;
}

.file-upload__button {
    -webkit-appearance: none;
    background: #0dcaf0;
    border: 2px solid #0dcaf0;
    border-radius: 4px;
    outline: none;
    padding: 0.5em 0.8em;
    margin-right: 15px;
    color: #ffffff;
    font-size: 1em;
    font-family: sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.file-upload__button:active {
    background: #0cbbdf;
}

.file-upload__label {
    max-width: 250px;
    font-size: 0.95em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
